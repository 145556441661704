import React from 'react';
import { GlobalContextProvider } from './src/context/globalContext';
import { DialogProvider } from '@rocketmakers/armstrong';

export const wrapRootElement = ({ element }) => (
  <GlobalContextProvider>
    <DialogProvider>{element}</DialogProvider>
  </GlobalContextProvider>
);
// ES5 way
// exports.onClientEntry = () => {
// ES6 way

// export const onClientEntry = () => {
//   // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
//   if (typeof window.IntersectionObserver === `undefined`) {
//     import(`intersection-observer`);
//     console.log(`# IntersectionObserver is polyfilled!`);
//   }
// };
