// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-templates-project-tsx": () => import("./../src/templates/project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */)
}

