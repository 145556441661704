import * as React from 'react';

import useMedia from '../hooks/useMediaQuery';

interface IContextState {
  isMobile?: boolean;
}
export const defaultState: IContextState = {};

export const GlobalContext = React.createContext(defaultState);

export const GlobalContextProvider: React.FunctionComponent = (props) => {
  const small = useMedia(['(max-width: 800px)', '(min-width: 800px)'], [true, false], false);
  const [isMobile, setIsMobile] = React.useState<boolean>(small);

  React.useEffect(() => {
    setIsMobile(small);
  }, [small]);

  return <GlobalContext.Provider value={{ isMobile }}>{props.children}</GlobalContext.Provider>;
};
